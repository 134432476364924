import { MapProperty, PropertyDisabledConfig, PropertyOrBuilder, StringProperty } from "firecms";
import { hashFilename } from "../util";

export type Translations<T = string> = {
  de?: T;
  en?: T;
  fr?: T;
};

export type GSImageUrl = string;

export function getIDProperty(): StringProperty {
  return {
    dataType: "string",
    ...isOnlyVisibleFor(false),
  };
}

export function getTranslatedImageProperty(
  overridesMap: Partial<MapProperty<Translations>> | undefined,
  overridesImage: {
    collection: string;
    width: number;
    height: number;
    required: boolean;
  }
): MapProperty<Translations<GSImageUrl>> {
  function getProp(language: keyof Translations): PropertyOrBuilder<string, Translations<string>> {
    return getImageProperty({
      collection: overridesImage.collection,
      name: language,
      description: undefined,
      width: overridesImage.width,
      height: overridesImage.height,
      required: overridesImage.required,
    });
  }

  return {
    dataType: "map",
    defaultValue: null as unknown as undefined,
    properties: {
      de: getProp("de"),
      en: getProp("en"),
      fr: getProp("fr"),
    },
    ...overridesMap,
  };
}

export function getTranslatedProperty(
  overridesMap?: Partial<MapProperty<Translations>>,
  overridesString?: Partial<StringProperty>
): MapProperty<Translations<string>> {
  function getProp(language: keyof Translations): PropertyOrBuilder<string, Translations<string>> {
    return {
      dataType: "string",
      name: language,
      description: undefined,
      defaultValue: null as unknown as undefined,
      validation: {
        required: language === "fr" ? false : true,
      },
      ...overridesString,
    };
  }

  return {
    dataType: "map",
    defaultValue: null as unknown as undefined,
    properties: {
      de: getProp("de"),
      en: getProp("en"),
      fr: getProp("fr"),
    },
    ...overridesMap,
  };
}

export function getImageProperty(opts: {
  collection: string;
  name: string;
  description?: string;
  width?: number;
  height?: number;
  required: boolean;
}) {
  if ((opts.width === undefined) !== (opts.height === undefined)) {
    throw new Error("Image either has both resolutions specified or none.");
  }

  const hasResolutions = opts.width !== undefined && opts.height !== undefined;

  const folderName = hasResolutions ? `${opts.width}x${opts.height}` : "anyres";

  return {
    name: `${opts.name} (${folderName})`,
    dataType: "string" as "string",
    description: opts.description,
    defaultValue: null as unknown as undefined,
    validation: {
      required: opts.required,
    },
    storage: {
      storagePath: `images/${opts.collection}/${folderName}`,
      acceptedFiles: ["image/*"],
      postProcess: async (pathOrUrl: string) => `gs://die-ringe-bodyworkout-app.appspot.com/${pathOrUrl}`,
      fileName: hashFilename,
    },
  } as PropertyOrBuilder<string, any>;
}

export function getHttpImageProperty(opts: {
  collection: string;
  name: string;
  description?: string;
  width?: number;
  height?: number;
  required: boolean;
}) {
  if ((opts.width === undefined) !== (opts.height === undefined)) {
    throw new Error("Image either has both resolutions specified or none.");
  }

  const hasResolutions = opts.width !== undefined && opts.height !== undefined;

  const folderName = hasResolutions ? `${opts.width}x${opts.height}` : "anyres";

  return {
    name: `${opts.name} (${folderName})`,
    dataType: "string" as "string",
    description: opts.description,
    defaultValue: null as unknown as undefined,
    validation: {
      required: opts.required,
    },
    storage: {
      storagePath: `images/${opts.collection}/${folderName}`,
      acceptedFiles: ["image/*"],
      postProcess: async (pathOrUrl: string) => {
        const urlEncodedPath = encodeURIComponent(pathOrUrl);
        return `https://firebasestorage.googleapis.com/v0/b/die-ringe-bodyworkout-app.appspot.com/o/${urlEncodedPath}?alt=media`;
      },
      fileName: hashFilename,
    },
  } as PropertyOrBuilder<string, any>;
}

/**
 * Only display this property conditionally.
 * This generates the `disabled: ...` property.
 *
 * Usage: `...isOnlyVisibleFor(false),`
 * @param condition When to display this property
 */
export function isOnlyVisibleFor(condition: boolean):
  | {
      disabled: PropertyDisabledConfig;
    }
  | undefined {
  return condition
    ? undefined
    : {
        disabled: { hidden: true },
      };
}
